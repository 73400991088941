import React from "react";
import AssessmentReportSectionNavigationComponent from "./actionContainer/AssessmentReportSectionNavigationComponent";
import AssessmentReportSectionNavigationComponentForInvestmentReadiness from "./actionContainer/AssessmentReportSectionNavigationComponentForInvestmentReadiness";
import AssessmentReportSectionNavigationComponentForExecutableSolutions from "./actionContainer/AssessmentReportSectionNavigationComponentForExecutableSolutions";
import AssessmentReportSectionNavigationComponentForBenchmark from "./actionContainer/AssessmentReportSectionNavigationComponentForBenchmark";
import "../../css/AssessmentReportActionContainer.css";

const AssessmentReportActionContainer = ({
    parentStateForActiveTab,
    analysisTabRefs,
    analysisTabData,
    executableSolutionsTabData,
    benchmarksTabData,
    investmentReadinessTabData,
    completeReportData }) => {

    return (
        <div className="actionsContainer">
            {parentStateForActiveTab === "Analysis" && (
                <AssessmentReportSectionNavigationComponent parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} analysisTabData={analysisTabData} />
            )}
            {parentStateForActiveTab === "Investment Readiness" && (
                <AssessmentReportSectionNavigationComponentForInvestmentReadiness parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} investmentReadinessTabData={investmentReadinessTabData} />
            )}
            {parentStateForActiveTab === "Executable solutions" && (
                <AssessmentReportSectionNavigationComponentForExecutableSolutions parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} executableSolutionsTabData={executableSolutionsTabData} />
            )}

            {parentStateForActiveTab === "Benchmarks" && (
                <AssessmentReportSectionNavigationComponentForBenchmark parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} benchmarksTabData={benchmarksTabData} />
            )}
        </div>
    );
};

export default AssessmentReportActionContainer;