import React from "react";
import {useLocation} from "react-router-dom";
import ReportDetails from "./ReportDetails";

export const DownloadReportPage = () => {
    const location = useLocation();

    const { analysisTabData } = location.state || {};
    const { executableSolutionsTabData } = location.state || {};
    const { benchmarksTabData } = location.state || {};
    const { investmentReadinessTabData } = location.state || {};
    const { completeReportData } = location.state || {};

    console.log(analysisTabData,executableSolutionsTabData,benchmarksTabData,investmentReadinessTabData,completeReportData)

  return (
      <ReportDetails
          analysisTabData={analysisTabData}
          executableSolutionsTabData={executableSolutionsTabData}
          benchmarksTabData={benchmarksTabData}
          investmentReadinessTabData={investmentReadinessTabData}
          completeReportData={completeReportData}
      />
  )
}