import {Modal} from "antd";
import React, {useState} from "react";

export function ModalDialog(props){

    const {show,oncancel, title, size} = props;

    return(
        <Modal visible={show} onCancel={oncancel} width={parseInt(size)} footer={false} destroyOnClose={true}>
            <h5 className='text-blue'>{title}</h5>
            {props.children}
        </Modal>
    )
}