import React, {useEffect, useState} from "react";
import {getUserDetails, notifyUser} from "../../Utils/general";
import {Input, Spin} from 'antd';
import {SingleFile} from "./SingleFile";
import {ModalDialog} from "../modal";
import {serverRequest} from "../../Utils/server_request";

export const RoomFileList = ({ documents, completedUploads }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [professionals, setProfessionals] = useState([]);
    const [invitedProfessionals, setInvitedProfessionals] = useState([]);
    const [inviteModal, setInviteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [professionalId, setProfessionalId] = useState('');

    useEffect(() => {
        if (completedUploads)
            getAllProfessionals()

        getGrantedAccessProfessionals()
    },[])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);

        if (searchTerm.length > 2){
            const filteredDocuments = documents.filter((document) =>
                document.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDocs(filteredDocuments);
        }else{
            setFilteredDocs([])
        }
    };

    const toggleModal = () => setInviteModal(prevState => !prevState)

    const getAllProfessionals = () => {
      serverRequest('GET', 'all-users?type=professional')
          .then(result => {
              setProfessionals(result.data)
          })
    }

    const provideAccess = () => {
      if (professionalId && professionalId !== ''){
          setLoading(true)
          serverRequest('POST', 'provide-data-room-access', {professionalId})
              .then(result => {
                  setLoading(false)
                  if (result && result?.success){
                      notifyUser('success', result?.message, 'Access Provided')
                      getGrantedAccessProfessionals()
                      toggleModal()
                  }else{
                      notifyUser('error', result.message, 'Access')
                  }
              }).catch(error =>{
              setLoading(false)
              notifyUser('error', error.message, 'Failed')
          })
      }else{
            notifyUser('error', 'Please select a professional', 'Failed')
        }
    }

    const getGrantedAccessProfessionals = () => {
      serverRequest('GET',  'business/data-room/access')
          .then(result  => {
              setInvitedProfessionals(result.data)
          })
    }

  return (
      <div className=''>
        <div className="row align-items-center">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <p className="fw-bold lead mb-0">Data Room for {getUserDetails()?.business_name}</p>
                {completedUploads ?
                    <small style={{cursor:'pointer'}} className='text-success fw-bold'><i className="bi bi-patch-check-fill"/> All documents uploaded</small>
                    :
                    <small style={{cursor:'pointer'}} className='text-warning fw-bold' onClick={() => window.location.href = '/data-room/upload?step=Pitch Deck'}><i className="bi bi-info-circle"/> Upload all documents to invite professionals</small>
                }
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <Input size="large" placeholder="Search your files by category" prefix={<i className='bi bi-search'/>} suffix={<i className='bi bi-sliders'/>}
                       value={searchTerm}
                       onChange={handleSearchChange} />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                {completedUploads ?
                <button className="btn btn-primary btn-small w-50 float-end mobile-full-width" onClick={toggleModal}><i className="bi bi-person-add"/> Invite</button> :  ''}
            </div>
        </div>
        <div className="row" style={{paddingTop:'8%'}}>
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="row">
                    {filteredDocs.length > 0 && filteredDocs.map(item =>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4" key={item.id}>
                            <SingleFile title={item.name} subTitle='Created 1min ago' file={item.files} status={item.status}/>
                        </div>
                    )}
                    {filteredDocs.length < 1 && documents.map(item =>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4" key={item.id}>
                            <SingleFile title={item.name} subTitle='Created 1min ago' file={item.files} status={item.status}/>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                {invitedProfessionals.length > 0 ?
                    <div>
                        <p className="">Professionals with Data Room Accesses</p>
                        {invitedProfessionals.map(user =>
                            <div className="shadow mb-3 p-3">
                                <p className='mb-0 fw-bold'>{user.business_name} <i className="bbi bi-patch-check-fill text-success"/></p>
                                <small className='mb-0'>{user.name}</small>
                            </div>
                        )}
                    </div>
                    : ''}
            </div>
        </div>
        <ModalDialog show={inviteModal} oncancel={toggleModal} title='Provide access to my Data Room' size={600}>
            <Spin spinning={loading}>
                <div className="form-group mt-5 mb-5">
                    <label htmlFor="professional">Please select a professional</label>
                    <select name="" id="" className='form-control form-select-sm' onChange={e => setProfessionalId(e.target.value)}>
                        <option></option>
                        {professionals.map(professional => <option value={professional.id}>{professional.business_name}</option>)}
                    </select>
                </div>
                <div align='center'>
                    <button className="btn btn-small btn-primary" onClick={provideAccess}>Invite Professional</button>
                </div>
            </Spin>
        </ModalDialog>
      </div>
  )
}