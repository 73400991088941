import React, {useState} from "react";
import {Input, Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import {serverRequest} from "../../Utils/server_request";
import {notifyUser} from "../../Utils/general";
import secureLocalStorage from "react-secure-storage";

export const VerifyAccountOTP = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [otpValue, setOTPValue] = useState('')

  const onChange = (text) => { setOTPValue(text) };

  const sharedProps = { onChange};

  const verifyAccount = () => {
    if (otpValue === undefined || otpValue === null || otpValue === ''){
      notifyUser('error', 'Please enter OTP value received in your email', 'Validation')
      return;
    }
    setLoading(true)
    serverRequest('POST','verify-account', {otp: otpValue}).then(result => {
      if (result?.data){
        secureLocalStorage.setItem('loggedInToken', result?.access_token)
        secureLocalStorage.setItem('loggedInUser', JSON.stringify(result?.data));
        sessionStorage.setItem("loggedInUserID", result.data.id);
        const user_details = {
          "logged_in_user_id": result.data.id,
          "user_access_token": result.access_token,
          "user_name": result.data.name,
        }
        sessionStorage.setItem("user_details", JSON.stringify(user_details));
        window.location.href = "/dashboard";
      }else{
        notifyUser('error', 'Sorry, we could not verify your account!', 'Verification Failed')
        setLoading(false)
      }
    }).catch(error => {
      setLoading(false)
      notifyUser('error', error.message, 'Verification Failed')
    })
  }

  return (
      <div>
        <div className="w-50 mx-auto mobile-full-width mobile-padding-top">
          <div align='center'>
            <h2 className="fw-bold">Welcome to Anvillist 👋</h2>
            <small>Your trusted partner on the path to investment readiness.</small>
          </div>

          <div style={{ paddingTop:'10%' }} className='w-75 mx-auto mobile-full-width'>
            <h2 className="fw-bold mb-3">Enter OTP sent to your email</h2>
            <small>Check your inbox.</small>

            <Spin spinning={loading}>
              <div className="form-group mb-4 mt-5">
                <label htmlFor="email">OTP</label><br/>
                <Input.OTP formatter={(str) => str.replace(/\D/g, '')} maxLength={6} length={6} {...sharedProps} />
              </div>
              <button className="btn btn-primary btn-small fw-bold w-100" onClick={verifyAccount}>Verify Account</button>
            </Spin>

            <hr className='mt-5'/>
            
            <div className="row align-items-center">
              <div className="col"><p className="mb-0 fw-bold">Already have an account?</p></div>
              <div className="col"><button className="btn btn-primary btn-small float-end" onClick={() => navigate('/login')}>Login</button></div>
            </div>
          </div>
        </div>
      </div>
  )
}