import React, {useState} from "react";
import {Input, Spin} from 'antd';
import {useNavigate} from "react-router-dom";
import {notifyUser} from "../../Utils/general";
import {serverRequest} from "../../Utils/server_request";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const submitEmail = () => {
    if (email != null || email !== undefined || email !== '') {
      setLoading(true)
      serverRequest('POST', 'password/request-otp', {email}).then(result =>{
        if(result?.success){
          navigate('/resetPassword', { state: { email } })
        }else{
          setLoading(false)
          notifyUser('error', result?.message, 'Verification failed')
        }
      }).catch(error => {
        setLoading(false)
        notifyUser('error', error?.message, 'Failed')
      })
    }else{
      notifyUser('error', 'Email field is required!', 'Validation Error')
    }
  }

  return (
      <div>
        <div className="w-50 mx-auto mobile-full-width mobile-padding-top">
          <div align='center'>
            <h2 className="fw-bold">Welcome to Anvillist 👋</h2>
            <small>Your trusted partner on the path to investment readiness.</small>
          </div>

          <div style={{ paddingTop:'10%' }} className='w-75 mx-auto mobile-full-width'>
            <h2 className="fw-bold mb-3">Forgot Password?</h2>
            <small>Don’t worry fill in your email and we will send you a link to reset your password.</small>

            <Spin spinning={loading}>
              <div className="form-group mb-4 mt-5">
                <label htmlFor="email">Email</label>
                <Input type="email" name='email' placeholder="Enter business email" value={email} onChange={e => setEmail(e.target.value)}/>
              </div>
              <button className="btn btn-primary btn-small fw-bold w-100" onClick={submitEmail}>Send an email</button>
            </Spin>

            <hr className='mt-5'/>
            
            <div className="row align-items-center">
              <div className="col"><p className="mb-0 fw-bold">Already have an account?</p></div>
              <div className="col"><button className="btn btn-primary btn-small float-end" onClick={() => navigate('/login')}>Login</button></div>
            </div>
          </div>
        </div>
      </div>
  )
}