import React, {useEffect, useState} from "react";
import {FreshAccessUser} from "./FreshAccessUser";
import {RoomFileList} from "./RoomFileList";
import {UploadFiles} from "./UploadFiles";
import {serverRequest} from "../../Utils/server_request";
import {Spin} from "antd";

export const DataRoomPage = () => {

  const [hasData, setHasData] = useState(false)
  const [completedUploads, setCompletedUploads] = useState(true)
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])

  useEffect(()  => {
    getDocuments()
    renderComponents()
  },[])

  const getDocuments = () => {
    setLoading(true)
    serverRequest('GET', 'data-room-documents').then(result => {
      result.documents.map(item=>{
        if (item.status === 'completed'){
          setHasData(true)
        }
        if (item.status === 'pending'){
          setCompletedUploads(false)
        }
      })
      setDocuments(result.documents)
      setLoading(false)
    })
  }

  const renderComponents = () => {
    if (hasData){
      return <RoomFileList documents={documents} completedUploads={completedUploads}/>;
    }else{
      return <UploadFiles documents={documents} />;
    }
  }

  return (
      <>{loading ? <Spin spinning={loading}/> : renderComponents() }</>
  )
}