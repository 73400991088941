import React, {useRef, useState} from "react";
import "../../css/AssessmentReportDetailContainer.css";
import AssessmentAnalysisContent from "../../components/viewAssessmentReport/Analysis/AssessmentAnalysisContent";
import AssessmentInvestmentReadinessContent
    from "../../components/viewAssessmentReport/investmentReadiness/AssessmentInvestmentReadinessContent";
import AssessmentExecutableSolutionsContent
    from "../../components/viewAssessmentReport/executableSolutions/AssessmentExecutableSolutionsContent";
import AssessmentBenchmarksContent from "../../components/viewAssessmentReport/benchmarks/AssessmentBenchmarksContent";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";


import {Spin} from "antd";
import {notifyUser} from "../../Utils/general";

const ReportDetails = ({
    parentStateForActiveTab,
    analysisTabData,
    executableSolutionsTabData,
    benchmarksTabData,
    investmentReadinessTabData,
    completeReportData
}) => {

    const [loading, setLoading] = useState(false)
    // Create refs for the Analysis section
    const marketOpportunityRef = useRef(null);
    const competitiveLandscapeRef = useRef(null);
    const productAndDevelopmentStageRef = useRef(null);
    const financialMetricsRef = useRef(null);
    const tractionAndMarketPenetrationRef = useRef(null);
    const riskAnalysisRef = useRef(null);
    const teamAndExperienceRef = useRef(null);
    const governanceAndSupportRef = useRef(null);
    const revenueModelAndGrowthRef = useRef(null);
    const exitStratergyRef = useRef(null);
    const recommendationsRef = useRef(null);
    const strengthAndWeaknessRef = useRef(null);
    const fundingandCapitalRequirementsAnalysisRef = useRef(null);

    // refs for the investment readniessTab
    const companyOverviewRef = useRef(null);
    const operationEfficiencyRef = useRef(null);
    const investmentReadinessConclusionRef = useRef(null);
    const investmentOpportunityRef = useRef(null);

    // refs for the executable solutions
    const conclusionSectionRef = useRef(null);

    // refs for benchmark
    const summaryOfRankingRef = useRef(null);
    const fundingAndCapitalRequirementsRef = useRef(null);

    const analysisTabRefs = {
        marketOpportunity: marketOpportunityRef,
        competitiveLandscape: competitiveLandscapeRef,
        productAndDevelopmentStage: productAndDevelopmentStageRef,
        financialMetrics: financialMetricsRef,
        tractionAndMarketPenetration: tractionAndMarketPenetrationRef,
        riskAnalysis: riskAnalysisRef,
        teamAndExperience: teamAndExperienceRef,
        governanceAndSupport: governanceAndSupportRef,
        revenueModelAndGrowth: revenueModelAndGrowthRef,
        exitStratergy: exitStratergyRef,
        recommendations: recommendationsRef,
        strengthAndWeakness: strengthAndWeaknessRef,
        companyOverview: companyOverviewRef,
        operationEfficiency: operationEfficiencyRef,
        investmentReadinessConclusion: investmentReadinessConclusionRef,
        investmentOpportunity: investmentOpportunityRef,
        conclusion: conclusionSectionRef,
        summaryOfRanking: summaryOfRankingRef,
        fundingAndCapitalRequirements: fundingAndCapitalRequirementsRef,
        fundingandCapitalRequirementsAnalysis: fundingandCapitalRequirementsAnalysisRef
    };

    const toggleLoading = () => setLoading(prevState => !prevState)

    const reportTemplateRef = useRef(null);

    const downloadReport = async () => {
        try {
            toggleLoading()
            const reportSections = document.querySelectorAll('.report-section'); // All sections to include
            const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, A4 size

            for (let i = 0; i < reportSections.length; i++) {
                const section = reportSections[i];

                // Adjust canvas scale for better quality
                const canvas = await html2canvas(section, {scale: 2});
                const imgData = canvas.toDataURL('image/png');

                const imgWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                // Add image to PDF
                if (i > 0) pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            }
            toggleLoading()
            pdf.save('Business_Report.pdf');
        } catch (e) {
            toggleLoading()
            notifyUser('error',e.message,'Download')
        }
    };

    const handleGeneratePdf = () => {
        toggleLoading()
        const doc = new jsPDF({
            orientation: 'l',
            format: 'a4',
            unit: 'px',
        });

        // Adjust the default font size
        // doc.setFontSize(9);  // Reduced from default (16)
        // doc.setFont('Inter-Regular', 'normal');

        doc.html(reportTemplateRef.current, {
            margin: [8, 8, 8, 8], // Add margins for clean spacing
            autoPaging: 'text',       // Enable automatic page breaks
            x: 5,                    // Horizontal padding
            y: 5,                    // Vertical padding
            // width: 550,               // Fit content within A4 width
            // windowWidth: 1024,        // Ensure full content width is captured

            async callback(doc) {
                toggleLoading()
                await doc.save('Business_Report.pdf');
            },
        });
    };

    return (
        <div>
            <button className='btn btn-primary fw-bold w-25 mobile-full-width'  onClick={handleGeneratePdf}>Download Report <i className="bi bi-file-pdf"/></button>
            <div id="report-content" ref={reportTemplateRef}>
                <Spin spinning={loading}>
                    <section style={{ width: '100%' }}>
                        <h2 className="mb-5 mt-5 fw-bold">Business Analysis</h2>
                        <AssessmentAnalysisContent
                            parentStateForActiveTab={parentStateForActiveTab}
                            analysisTabRefs={analysisTabRefs}
                            analysisTabData={analysisTabData}
                            completeReportData={completeReportData}
                        />
                    </section>
                    <section style={{ width: '100%' }}>
                        <h2 className="mb-5 mt-5 fw-bold">Executable Solutions</h2>
                        <AssessmentExecutableSolutionsContent
                            parentStateForActiveTab={parentStateForActiveTab}
                            analysisTabRefs={analysisTabRefs}
                            executableSolutionsTabData={executableSolutionsTabData} />
                    </section>
                    <section style={{ width: '100%' }}>
                        <h2 className="mb-5 mt-5 fw-bold">Benchmark Analysis</h2>
                        <AssessmentBenchmarksContent
                            parentStateForActiveTab={parentStateForActiveTab}
                            analysisTabRefs={analysisTabRefs}
                            benchmarksTabData={benchmarksTabData} />
                    </section>
                    <section style={{ width: '100%' }}>
                        <h2 className="mb-5 mt-5 fw-bold">Investment Readiness Report</h2>
                        <AssessmentInvestmentReadinessContent
                            parentStateForActiveTab={parentStateForActiveTab}
                            analysisTabRefs={analysisTabRefs}
                            analysisTabData={analysisTabData}
                            executableSolutionsTabData={executableSolutionsTabData}
                            benchmarksTabData={benchmarksTabData}
                            investmentReadinessTabData={investmentReadinessTabData}
                            completeReportData={completeReportData} />
                    </section>
                </Spin>
            </div>
        </div>
    );
};

export default ReportDetails;