import React, { useEffect, useReducer, useRef, useState } from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import AssessmentReportDetailContainer from "../components/viewAssessmentReport/AssessmentReportDetailContainer";
import AssessmentReportActionContainer from "../components/viewAssessmentReport/AssessmentReportActionContainer";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../css/viewAssessmentReport.css"

const isNavbarForDashboard = true;

const ViewAssessmentReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { assessmentReportData } = location.state || {};
    const { completeReportData } = location.state || {};

    // Extract reports based on the stage
    const analysisTabData = assessmentReportData.find(report => report.stage === "1");
    const executableSolutionsTabData = assessmentReportData.find(report => report.stage === "2");
    const benchmarksTabData = assessmentReportData.find(report => report.stage === "3");
    const investmentReadinessTabData = assessmentReportData.find(report => report.stage === "4");

    // Create refs for the Analysis section
    const marketOpportunityRef = useRef(null);
    const competitiveLandscapeRef = useRef(null);
    const productAndDevelopmentStageRef = useRef(null);
    const financialMetricsRef = useRef(null);
    const tractionAndMarketPenetrationRef = useRef(null);
    const riskAnalysisRef = useRef(null);
    const teamAndExperienceRef = useRef(null);
    const governanceAndSupportRef = useRef(null);
    const revenueModelAndGrowthRef = useRef(null);
    const exitStratergyRef = useRef(null);
    const recommendationsRef = useRef(null);
    const strengthAndWeaknessRef = useRef(null);
    const fundingandCapitalRequirementsAnalysisRef = useRef(null);

    // refs for the investment readniessTab
    const companyOverviewRef = useRef(null);
    const operationEfficiencyRef = useRef(null);
    const investmentReadinessConclusionRef = useRef(null);
    const investmentOpportunityRef = useRef(null);

    // refs for the executable solutions
    const conclusionSectionRef = useRef(null);

    // refs for benchmark
    const summaryOfRankingRef = useRef(null);
    const fundingAndCapitalRequirementsRef = useRef(null);

    const analysisTabRefs = {
        marketOpportunity: marketOpportunityRef,
        competitiveLandscape: competitiveLandscapeRef,
        productAndDevelopmentStage: productAndDevelopmentStageRef,
        financialMetrics: financialMetricsRef,
        tractionAndMarketPenetration: tractionAndMarketPenetrationRef,
        riskAnalysis: riskAnalysisRef,
        teamAndExperience: teamAndExperienceRef,
        governanceAndSupport: governanceAndSupportRef,
        revenueModelAndGrowth: revenueModelAndGrowthRef,
        exitStratergy: exitStratergyRef,
        recommendations: recommendationsRef,
        strengthAndWeakness: strengthAndWeaknessRef,
        companyOverview: companyOverviewRef,
        operationEfficiency: operationEfficiencyRef,
        investmentReadinessConclusion: investmentReadinessConclusionRef,
        investmentOpportunity: investmentOpportunityRef,
        conclusion: conclusionSectionRef,
        summaryOfRanking: summaryOfRankingRef,
        fundingAndCapitalRequirements: fundingAndCapitalRequirementsRef,
        fundingandCapitalRequirementsAnalysis: fundingandCapitalRequirementsAnalysisRef
    };

    // Define the state in the parent component
    const [parentStateForActiveTab, setParentStateForActiveTab] = useState('Analysis');

    // Function to update the state from child
    const updateStateFromChild = (childData) => {
        setParentStateForActiveTab(childData);
    };

    return (
        <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <AssessmentReportDetailContainer
                    parentStateForActiveTab={parentStateForActiveTab}
                    sendDataToParent={updateStateFromChild}
                    analysisTabRefs={analysisTabRefs}
                    analysisTabData={analysisTabData}
                    executableSolutionsTabData={executableSolutionsTabData}
                    benchmarksTabData={benchmarksTabData}
                    investmentReadinessTabData={investmentReadinessTabData}
                    completeReportData={completeReportData}
                />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <button className="btn btn-dark mb-3 fw-bold" style={{ width: "75%" }} onClick={null}>Generate & Download Document <i className="bi bi-file-pdf"/></button>
                <AssessmentReportActionContainer
                    parentStateForActiveTab={parentStateForActiveTab}
                    analysisTabRefs={analysisTabRefs}
                    analysisTabData={analysisTabData}
                    executableSolutionsTabData={executableSolutionsTabData}
                    benchmarksTabData={benchmarksTabData}
                    investmentReadinessTabData={investmentReadinessTabData}
                    completeReportData={completeReportData}
                />
            </div>
        </div>
    )
};

export default ViewAssessmentReport;
