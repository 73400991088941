import React, {useEffect, useState} from "react";
import {Input, Spin} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {isFormComplete, notifyUser} from "../../Utils/general";
import {serverRequest} from "../../Utils/server_request";

export const ResetPasswordOTP = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [form, setForm] = useState({ email: '', password: '', c_password: '', otp: '' })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  useEffect(() => {
    const email = location.state?.email;
    setForm({...form, email: email})
  },[])

  const onChange = (text) => { setForm({...form, otp: text}) };

  const toggleLoading = () => setLoading(prevState => !prevState)

  const sharedProps = { onChange};

  const changeHandler = (e) => setForm({...form, [e.target.name]: e.target.value})

  const handleSubmit = () => {
    if (isFormComplete(form)) {
      if (!passwordRegex.test(form.password)) {
        setError('Password must be at least 8 characters, include uppercase, lowercase, number, and symbol.');
        return;
      }else{setError('')}

      if (form.password !== form.c_password){
        setError('Password must be equal to confirm password');
        return;
      }else{setError('')}

      toggleLoading()
      serverRequest('POST', 'password/reset', form).then(result => {
        if (result.success){
          notifyUser('success', 'Your password reset was successful', 'Password Reset')
          navigate('/login')
        }else{
          toggleLoading()
          notifyUser('error', result?.message, 'Password Reset')
        }
      }).catch(error => {
        toggleLoading()
        notifyUser('error', error?.message, 'Password Reset')
      })
    }else {
      notifyUser('error', 'All fields are required!', 'Validation Error')
    }
  }

  return (
      <div>
        <div className="w-50 mx-auto mobile-full-width mobile-padding-top">
          <div align='center'>
            <h2 className="fw-bold">Welcome to Anvillist 👋</h2>
            <small>Your trusted partner on the path to investment readiness.</small>
          </div>

          <div style={{ paddingTop:'10%' }} className='w-75 mx-auto mobile-full-width'>
            <h2 className="fw-bold mb-3">Enter OTP and New Password</h2>
            <small>Check your inbox.</small>

            <Spin spinning={loading}>
              <div className="form-group mb-4 mt-5">
                <label htmlFor="email">OTP</label><br/>
                <Input.OTP formatter={(str) => str.replace(/\D/g, '').toUpperCase()} maxLength={6} length={6} {...sharedProps} />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email">New Password</label>
                <Input.Password name="password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Enter password" value={form.password} onChange={changeHandler}/>
                {error && <small style={{ color: 'red' }}>{error}</small>}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email">Confirm Password</label>
                <Input.Password name="c_password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Enter password" value={form.c_password} onChange={changeHandler}/>
              </div>
              <button className="btn btn-primary btn-small fw-bold w-100" onClick={handleSubmit}>Reset Password</button>
            </Spin>

            <hr className='mt-5'/>
            
            <div className="row align-items-center">
              <div className="col"><p className="mb-0 fw-bold">Already have an account?</p></div>
              <div className="col"><button className="btn btn-primary btn-small float-end" onClick={() => navigate('/login')}>Login</button></div>
            </div>
          </div>
        </div>
      </div>
  )
}